import React from 'react'
import { withAccordion } from '../hoc/withAccordion'
const FAQAccordion = ({question, answer, index, openAccordion, toggleAccordion }) => {
  let isOpen = index===openAccordion;
  return (
        <div className="max-w-2xl mx-auto bg-gradient-to-bl from-orange-50 to-amber-100 shadow-md rounded-md p-4 mb-4 border-l-4 border-amber-600">
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => toggleAccordion(index)}
        >
          <h3 className="mf:text-[13px] lg:text-lg font-semibold  text-black">{question}</h3>
          <svg
            className={`w-6 h-6  fill-black transition-transform transform ${
              isOpen ? 'rotate-180' : 'rotate-0'
            }`}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M19 9l-7 7-7-7"></path>
          </svg>
        </div>
        {isOpen && (
          <div className="mt-2">
            <p className="mf:text-[12px] lg:text-base  text-slate-700">{answer}</p>
          </div>
        )}
      </div>
  )
}

const FAQWithAccordion = withAccordion(FAQAccordion)
export default FAQWithAccordion;