import React, { useState } from 'react'

export const Timeline = () => {
    const [trutie, setTrutie] = useState(false)
    const [trutie1, setTrutie1] = useState(false)
    const [trutie2, setTrutie2] = useState(false)
    const [trutie3, setTrutie3] = useState(false)
    const [trutie4, setTrutie4] = useState(false)
    const [trutie5, setTrutie5] = useState(false)
    const [blur, SetBlur] = useState(0);
    const onChangeEfect1 = (trutie) => {
        setTrutie(trutie)
        setTrutie1(false)
        setTrutie2(false)
        setTrutie3(false)
        setTrutie4(false)
        setTrutie5(false)
        SetBlur(1)
        console.log(trutie, blur, "||", trutie1, blur)
    }
    const onChangeEfect2 = (trutie) => {
        setTrutie(false)
        setTrutie1(trutie)
        setTrutie2(false)
        setTrutie3(false)
        setTrutie4(false)
        setTrutie5(false)
        SetBlur(2)
        console.log(trutie, blur, "||", trutie1, blur)
    }
    const onChangeEfect3 = (trutie) => {
        setTrutie(false)
        setTrutie1(false)
        setTrutie2(trutie)
        setTrutie3(false)
        setTrutie4(false)
        setTrutie5(false)
        SetBlur(3)
    }
    const onChangeEfect4 = (trutie) => {
        setTrutie(false)
        setTrutie1(false)
        setTrutie2(false)
        setTrutie3(trutie)
        setTrutie4(false)
        setTrutie5(false)
        SetBlur(4)
    }
    const onChangeEfect5 = (trutie) => {
        setTrutie(false)
        setTrutie1(false)
        setTrutie2(false)
        setTrutie3(false)
        setTrutie4(trutie)
        setTrutie5(false)
        SetBlur(5)
    }
    const onChangeEfect6 = (trutie) => {
        setTrutie(false)
        setTrutie1(false)
        setTrutie2(false)
        setTrutie3(false)
        setTrutie4(false)
        setTrutie5(trutie)
        SetBlur(6)
    }

    return (
        <div className='flex flex-col justify-center items-center bg-gradient-to-tr from-amber-100 to-amber-300 pt-5 pb-10'>
            <h1 className='text-black text-3xl font-bold py-10'>Alur Pendaftaran </h1>
            <div className='mf:hidden lg:block xl:px-0 lg:px-20'>
                <ol class="items-center sm:flex py-5">
                    <li class={`relative mb-6 sm:mb-0  ${!trutie1 && !trutie2 && !trutie3 && !trutie4 && !trutie5 ? "blur-none" : blur === 1 ? "blur-none" : blur === 0 ? "blur-none" : "blur-xl"}`}>
                        <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 bg-black rounded-full ring-0 dark:bg-blue-900 sm:ring-8 ring-gray-900 shrink-0">
                                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="55.5" cy="55.5" r="55.5" fill="url(#paint0_linear_6_4)" />
                                    <path d="M62.1818 32.4545V79H56.5455V38.3636H56.2727L44.9091 45.9091V40.1818L56.5455 32.4545H62.1818Z" fill="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_6_4" x1="56" y1="56" x2="151" y2="-15" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#FA6E08" />
                                            <stop offset="1" stop-color="#FA6E08" stop-opacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>


                            </div>
                            <div class="hidden sm:flex w-full  h-0.5 bg-gray-700"></div>
                        </div>
                        <div class="mt-3 sm:pr-8">
                            <h3 class="2xl:text-lg 2xl:font-semibold lg:text-md lg:font-bold text-gray-900">Chat dan Booking Umroh ke Marketing</h3>
                            <time class="block mb-2 text-sm font-medium leading-none  ">Sebelum 17 November 2023 <br className='2xl:hidden lg:block' />(selama Seat Umroh Masih Tersedia)</time>
                            <p class="text-base font-normal text-gray-500 ">A. Konsultasi chat WA ke ustadz H. Surono Achmad, S.Pd </p>
                            {
                                trutie ? <> <p class="text-base font-normal text-gray-500 ">B. Bila menghendaki disediakan fasilitas pembiayaaan untuk umroh</p>
                                    <p class="text-base font-normal text-gray-500 ">Sebaiknya segera hubungi/chat marketing atau booking sekarang,<br /> karena keterbatasan seat umroh </p></> : null
                            }
                            <button className='text-white bg-gradient-to-br from-amber-600 via-red-600 to-fuchsia-600 px-6 py-2 flex  flex-row mt-5' onClick={() => onChangeEfect1(!trutie)}>Info Lebih Lanjut
                                <svg
                                    className={`w-6 h-6  transition-transform transform ${trutie ? 'rotate-180' : 'rotate-0'
                                        }`}
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M19 9l-7 7-7-7"></path>
                                </svg></button>
                        </div>
                    </li>
                    <li class={`relative mb-6 sm:mb-0 ${!trutie && !trutie2 && !trutie3 && !trutie4 && !trutie5 ? "blur-none" : blur === 2 ? "blur-none" : blur === 0 ? "blur-none" : "blur-xl"}`}>
                        <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 bg-black rounded-full ring-0 dark:bg-blue-900 sm:ring-8 ring-gray-900 shrink-0">
                                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="55.5" cy="55.5" r="55.5" fill="url(#paint0_linear_6_5)" />
                                    <path d="M41.8182 79V74.9091L57.1818 58.0909C58.9848 56.1212 60.4697 54.4091 61.6364 52.9545C62.803 51.4848 63.6667 50.1061 64.2273 48.8182C64.803 47.5152 65.0909 46.1515 65.0909 44.7273C65.0909 43.0909 64.697 41.6742 63.9091 40.4773C63.1364 39.2803 62.0758 38.3561 60.7273 37.7045C59.3788 37.053 57.8636 36.7273 56.1818 36.7273C54.3939 36.7273 52.8333 37.0985 51.5 37.8409C50.1818 38.5682 49.1591 39.5909 48.4318 40.9091C47.7197 42.2273 47.3636 43.7727 47.3636 45.5455H42C42 42.8182 42.6288 40.4242 43.8864 38.3636C45.1439 36.303 46.8561 34.697 49.0227 33.5455C51.2045 32.3939 53.6515 31.8182 56.3636 31.8182C59.0909 31.8182 61.5076 32.3939 63.6136 33.5455C65.7197 34.697 67.3712 36.25 68.5682 38.2045C69.7652 40.1591 70.3636 42.3333 70.3636 44.7273C70.3636 46.4394 70.053 48.1136 69.4318 49.75C68.8258 51.3712 67.7652 53.1818 66.25 55.1818C64.75 57.1667 62.6667 59.5909 60 62.4545L49.5455 73.6364V74H71.1818V79H41.8182Z" fill="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_6_5" x1="56" y1="56" x2="151" y2="-15" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#FA6E08" />
                                            <stop offset="1" stop-color="#FA6E08" stop-opacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div class="hidden sm:flex w-full  h-0.5 bg-gray-700"></div>
                        </div>
                        <div class="mt-3 sm:pr-8">
                            <h3 class="text-lg font-semibold text-gray-900 flex flex-row"><span className='2xl:flex lg:hidden 2xl:mr-2'>Mengumpulkan </span> Syarat Pendaftaran Umroh</h3>
                            <time class="block mb-2 text-sm font-medium leading-none  ">Sebelum 17 November 2023</time>
                            <p class="text-base font-normal text-gray-500 ">	A. Foto atau fotocopy KTP </p>
                            {
                                trutie1 ? <>
                                    <p class="text-base font-normal text-gray-500 ">	B. Foto atau fotocopy KK </p>
                                    <p class="text-base font-normal text-gray-500 ">	C. Foto atau fotocopy buku nikah bagi suami istri / foto atau fotocopy Akte Lahir foto atau fotocopy ijazah bagi yang belum menikah </p>
                                    <p class="text-base font-normal text-gray-500 ">	D. Pas foto ukuran 4 x 6 = 3 lembar latar belakang putih, <br /> tidak berkacamata, dan menggunakan hijab bagi perempuan. </p>
                                    <p class="text-base font-normal text-gray-500 ">	E. Pas foto ukuran 3 x 4 = 3 lembar latar belakang putih,<br /> tampak wajah 80%, pose senyum.</p>
                                    <p class="text-base font-normal text-gray-500 ">    F. Membayar DP minimal Rp. 5.000.000,-</p>
                                    <p class="text-base font-normal text-gray-500 ">    NB:Semua syarat diatas dikirimkan ke ustadz H. Surono Achmad, S.Pd melalui WA-</p>
                                </> : null
                            }


                            <button className='text-white bg-gradient-to-br from-amber-600 via-red-600 to-fuchsia-600 px-6 py-2 flex  flex-row mt-5' onClick={() => onChangeEfect2(!trutie1)}>Info Lebih Lanjut
                                <svg
                                    className={`w-6 h-6  transition-transform transform ${trutie1 ? 'rotate-180' : 'rotate-0'
                                        }`}
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M19 9l-7 7-7-7"></path>
                                </svg></button>
                        </div>
                    </li>
                    <li class={`relative mb-6 sm:mb-0 ${!trutie && !trutie1 && !trutie3 && !trutie4 && !trutie5 ? "blur-none" : blur === 3 ? "blur-none" : blur === 0 ? "blur-none" : "blur-xl"}`}>
                        <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 bg-black rounded-full ring-0 dark:bg-blue-900 sm:ring-8 ring-gray-900 shrink-0">
                                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="55.5" cy="55.5" r="55.5" fill="url(#paint0_linear_6_8)" />
                                    <path d="M58.5455 79.6364C55.5455 79.6364 52.8712 79.1212 50.5227 78.0909C48.1894 77.0606 46.3333 75.6288 44.9545 73.7955C43.5909 71.947 42.8485 69.803 42.7273 67.3636H48.4545C48.5758 68.8636 49.0909 70.1591 50 71.25C50.9091 72.3258 52.0985 73.1591 53.5682 73.75C55.0379 74.3409 56.6667 74.6364 58.4545 74.6364C60.4545 74.6364 62.2273 74.2879 63.7727 73.5909C65.3182 72.8939 66.5303 71.9242 67.4091 70.6818C68.2879 69.4394 68.7273 68 68.7273 66.3636C68.7273 64.6515 68.303 63.1439 67.4545 61.8409C66.6061 60.5227 65.3636 59.4924 63.7273 58.75C62.0909 58.0076 60.0909 57.6364 57.7273 57.6364H54V52.6364H57.7273C59.5758 52.6364 61.197 52.303 62.5909 51.6364C64 50.9697 65.0985 50.0303 65.8864 48.8182C66.6894 47.6061 67.0909 46.1818 67.0909 44.5455C67.0909 42.9697 66.7424 41.5985 66.0455 40.4318C65.3485 39.2651 64.3636 38.3561 63.0909 37.7045C61.8333 37.053 60.3485 36.7273 58.6364 36.7273C57.0303 36.7273 55.5152 37.0227 54.0909 37.6136C52.6818 38.1894 51.5303 39.0303 50.6364 40.1364C49.7424 41.2273 49.2576 42.5455 49.1818 44.0909H43.7273C43.8182 41.6515 44.553 39.5151 45.9318 37.6818C47.3106 35.8333 49.1136 34.3939 51.3409 33.3636C53.5833 32.3333 56.0455 31.8182 58.7273 31.8182C61.6061 31.8182 64.0758 32.4015 66.1364 33.5682C68.197 34.7197 69.7803 36.2424 70.8864 38.1364C71.9924 40.0303 72.5455 42.0758 72.5455 44.2727C72.5455 46.8939 71.8561 49.1288 70.4773 50.9773C69.1136 52.8258 67.2576 54.1061 64.9091 54.8182V55.1818C67.8485 55.6667 70.1439 56.9167 71.7955 58.9318C73.447 60.9318 74.2727 63.4091 74.2727 66.3636C74.2727 68.8939 73.5833 71.1667 72.2045 73.1818C70.8409 75.1818 68.9773 76.7576 66.6136 77.9091C64.25 79.0606 61.5606 79.6364 58.5455 79.6364Z" fill="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_6_8" x1="56" y1="56" x2="151" y2="-15" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#FA6E08" />
                                            <stop offset="1" stop-color="#FA6E08" stop-opacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>


                            </div>
                            <div class="hidden sm:flex w-full  h-0.5 bg-gray-700"></div>
                        </div>
                        <div class="mt-3 sm:pr-8">
                            <h3 class="text-lg font-semibold text-gray-900 ">Ketentuan Pembayaran DP dan Pelunasan</h3>
                            <time class="block mb-2 text-sm font-medium leading-none  ">Sebelum 17 November 2023</time>
                            <p class="text-base font-normal text-gray-500 ">
                                A. DP bisa ditransfer ke rekening PT. Madinah Iman Wisata <br />
                            </p>
                            {
                                trutie2 ? <p class="text-base font-normal text-gray-500 ">
                                    B. Mohon semua pembayaran melalui transfer ke rekening MIW di bawah ini atau <br />dibayarkan tunai di kantor MIW setempat dengan meminta tanda terima<br />
                                    Photocopy bukti transfer(bukti pembayaran) dan KTP dikirimkan ke WA ustadz H. Surono Achmad, S.Pd
                                    <br />
                                    C. Pelunasan  bisa ditransfer ke rekening PT. Madinah Iman Wisata
                                    <br /> Paling lambat  2 minggu sampai 1 bulan sebelum keberangkatan
                                    <br /><span className='font-extrabold text-lg text-red-500'>Rekening</span>
                                    <br /> <b><strong><span className='text-green-600 font-extrabold'>BSI</span> 7770000542</strong></b>
                                    <br /> <b><strong><span className='text-green-600 font-extrabold'>BRI</span> 0334.01002958.563</strong></b></p> : null
                            }


                            <button className='text-white bg-gradient-to-br from-amber-600 via-red-600 to-fuchsia-600 px-6 py-2 flex  flex-row mt-5' onClick={() => onChangeEfect3(!trutie2)}>Info Lebih Lanjut
                                <svg
                                    className={`w-6 h-6  transition-transform transform ${trutie2 ? 'rotate-180' : 'rotate-0'
                                        }`}
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M19 9l-7 7-7-7"></path>
                                </svg></button>
                        </div>
                    </li>
                </ol>
                <ol class="items-center sm:flex">

                    <li class={`relative mb-6 sm:mb-0 ${!trutie && !trutie2 && !trutie1 && !trutie4 && !trutie5 ? "blur-none" : blur === 4 ? "blur-none" : blur === 0 ? "blur-none" : "blur-xl"}`}>
                        <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 bg-black rounded-full ring-0 dark:bg-blue-900 sm:ring-8 ring-gray-900 shrink-0">
                                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="55.5" cy="55.5" r="55.5" fill="url(#paint0_linear_6_11)" />
                                    <path d="M38.7273 69.4545V64.8182L59.1818 32.4545H62.5455V39.6364H60.2727L44.8182 64.0909V64.4545H72.3636V69.4545H38.7273ZM60.6364 79V68.0455V65.8864V32.4545H66V79H60.6364Z" fill="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_6_11" x1="56" y1="56" x2="151" y2="-15" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#FA6E08" />
                                            <stop offset="1" stop-color="#FA6E08" stop-opacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div class="hidden sm:flex w-full  h-0.5 bg-gray-700"></div>
                        </div>
                        <div class="mt-3 sm:pr-8">
                            <h3 class="text-lg font-semibold text-gray-900 ">Pembuatan Passport dan Manasik  </h3>
                            <time class="block mb-2 text-sm font-medium leading-none  ">Sebelum 17 November 2023</time>
                            <p class="text-base font-normal text-gray-500 ">A. Bila berkenan Jamaah akan dibantu dalam <br />  pembuatan passport baru untuk umroh.<br />Tanpa dipunggut biaya.</p>
                            {
                                trutie3 ? <>
                                    <p class="text-base font-normal text-gray-500 ">B. Jamaah dibimbing manasik sebelum <br /> dan saat umroh oleh   pembimbing bersertifikat</p>

                                </> : null
                            }

                            <button className='text-white bg-gradient-to-br from-amber-600 via-red-600 to-fuchsia-600 px-6 py-2 flex  flex-row mt-5' onClick={() => onChangeEfect4(!trutie3)}>Info Lebih Lanjut
                                <svg
                                    className={`w-6 h-6  transition-transform transform ${trutie3 ? 'rotate-180' : 'rotate-0'
                                        }`}
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M19 9l-7 7-7-7"></path>
                                </svg></button>
                        </div>
                    </li>
                    <li class={`relative mb-6 sm:mb-0  ${!trutie && !trutie1 && !trutie2 && !trutie3 && !trutie5 ? "blur-none" : blur === 5 ? "blur-none" : blur === 0 ? "blur-none" : "blur-xl"}`}>
                        <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 bg-black rounded-full ring-0 dark:bg-blue-900 sm:ring-8 ring-gray-900 shrink-0">
                                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="55.5" cy="55.5" r="55.5" fill="url(#paint0_linear_6_14)" />
                                    <path d="M56.3636 79.6364C53.697 79.6364 51.2955 79.1061 49.1591 78.0455C47.0227 76.9848 45.3106 75.5303 44.0227 73.6818C42.7348 71.8333 42.0303 69.7273 41.9091 67.3636H47.3636C47.5758 69.4697 48.5303 71.2121 50.2273 72.5909C51.9394 73.9545 53.9848 74.6364 56.3636 74.6364C58.2727 74.6364 59.9697 74.1894 61.4545 73.2955C62.9545 72.4015 64.1288 71.1742 64.9773 69.6136C65.8409 68.0379 66.2727 66.2576 66.2727 64.2727C66.2727 62.2424 65.8258 60.4318 64.9318 58.8409C64.053 57.2348 62.8409 55.9697 61.2955 55.0455C59.75 54.1212 57.9848 53.6515 56 53.6364C54.5758 53.6212 53.1136 53.8409 51.6136 54.2955C50.1136 54.7348 48.8788 55.303 47.9091 56L42.6364 55.3636L45.4545 32.4545H69.6364V37.4545H50.1818L48.5455 51.1818H48.8182C49.7727 50.4242 50.9697 49.7955 52.4091 49.2955C53.8485 48.7955 55.3485 48.5455 56.9091 48.5455C59.7576 48.5455 62.2955 49.2273 64.5227 50.5909C66.7652 51.9394 68.5227 53.7879 69.7955 56.1364C71.0833 58.4848 71.7273 61.1667 71.7273 64.1818C71.7273 67.1515 71.0606 69.803 69.7273 72.1364C68.4091 74.4545 66.5909 76.2879 64.2727 77.6364C61.9545 78.9697 59.3182 79.6364 56.3636 79.6364Z" fill="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_6_14" x1="56" y1="56" x2="151" y2="-15" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#FA6E08" />
                                            <stop offset="1" stop-color="#FA6E08" stop-opacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div class="hidden sm:flex w-full  h-0.5 bg-gray-700"></div>
                        </div>
                        <div class="mt-3 sm:pr-8">
                            <h3 class="text-lg font-semibold text-gray-900 pb-4">Keberangkatan Umroh  </h3>
                            <time class="block mb-2 text-sm font-medium leading-none text-gray-900 ">17 Desember 2023</time>
                            <p class="text-base font-normal text-gray-500 ">A. Keberangkatan dari Bandara Adi Soemarmo Solo,<br /> jamaah langsung menuju bandara pada jam yang sudah ditentukan </p>
                            {

                                trutie4 ? <p class="text-base font-normal text-gray-500 ">B. Jika jamaah menghendaki berangkat dari kantor MIW setempat <br /> maka biaya transport dari kantor MIW ke Bandara Adi Soemarmo Solo ditanggung oleh calon jamaah</p> : null

                            }
                            <button className='text-white bg-gradient-to-br from-amber-600 via-red-600 to-fuchsia-600 px-6 py-2 flex  flex-row mt-5' onClick={() => onChangeEfect5(!trutie4)}>Info Lebih Lanjut
                                <svg
                                    className={`w-6 h-6  transition-transform transform ${trutie4 ? 'rotate-180' : 'rotate-0'
                                        }`}
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M19 9l-7 7-7-7"></path>
                                </svg></button>
                        </div>
                    </li>
                    <li class={`relative mb-6 sm:mb-0  ${!trutie && !trutie1 && !trutie2 && !trutie3 && !trutie4 ? "blur-none" : blur === 6 ? "blur-none" : blur === 0 ? "blur-none" : "blur-xl"}`}>
                        <div class="flex items-center">
                            <div class="z-10 flex items-center justify-center w-6 h-6 bg-black rounded-full ring-0 dark:bg-blue-900 sm:ring-8 ring-gray-900 shrink-0">
                                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="55.5" cy="55.5" r="55.5" fill="url(#paint0_linear_12_2)" />
                                    <path d="M57.2727 79.6364C55.3636 79.6061 53.4545 79.2424 51.5455 78.5455C49.6364 77.8485 47.8939 76.6742 46.3182 75.0227C44.7424 73.3561 43.4773 71.1061 42.5227 68.2727C41.5682 65.4242 41.0909 61.8485 41.0909 57.5455C41.0909 53.4242 41.4773 49.7727 42.25 46.5909C43.0227 43.3939 44.1439 40.7045 45.6136 38.5227C47.0833 36.3258 48.8561 34.6591 50.9318 33.5227C53.0227 32.3864 55.3788 31.8182 58 31.8182C60.6061 31.8182 62.9242 32.3409 64.9545 33.3864C67 34.4167 68.6667 35.8561 69.9545 37.7045C71.2424 39.553 72.0758 41.6818 72.4545 44.0909H66.9091C66.3939 42 65.3939 40.2652 63.9091 38.8864C62.4242 37.5076 60.4545 36.8182 58 36.8182C54.3939 36.8182 51.553 38.3864 49.4773 41.5227C47.4167 44.6591 46.3788 49.0606 46.3636 54.7273H46.7273C47.5758 53.4394 48.5833 52.3409 49.75 51.4318C50.9318 50.5076 52.2348 49.7955 53.6591 49.2955C55.0833 48.7955 56.5909 48.5455 58.1818 48.5455C60.8485 48.5455 63.2879 49.2121 65.5 50.5455C67.7121 51.8636 69.4848 53.6894 70.8182 56.0227C72.1515 58.3409 72.8182 61 72.8182 64C72.8182 66.8788 72.1742 69.5152 70.8864 71.9091C69.5985 74.2879 67.7879 76.1818 65.4545 77.5909C63.1364 78.9848 60.4091 79.6667 57.2727 79.6364ZM57.2727 74.6364C59.1818 74.6364 60.8939 74.1591 62.4091 73.2045C63.9394 72.25 65.1439 70.9697 66.0227 69.3636C66.9167 67.7576 67.3636 65.9697 67.3636 64C67.3636 62.0758 66.9318 60.3258 66.0682 58.75C65.2197 57.1591 64.0455 55.8939 62.5455 54.9545C61.0606 54.0152 59.3636 53.5455 57.4545 53.5455C56.0152 53.5455 54.6742 53.8333 53.4318 54.4091C52.1894 54.9697 51.0985 55.7424 50.1591 56.7273C49.2348 57.7121 48.5076 58.8409 47.9773 60.1136C47.447 61.3712 47.1818 62.697 47.1818 64.0909C47.1818 65.9394 47.6136 67.6667 48.4773 69.2727C49.3561 70.8788 50.553 72.1742 52.0682 73.1591C53.5985 74.1439 55.3333 74.6364 57.2727 74.6364Z" fill="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_12_2" x1="56" y1="56" x2="151" y2="-15" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#FA6E08" />
                                            <stop offset="1" stop-color="#FA6E08" stop-opacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div class="hidden sm:flex w-full  h-0.5 bg-gray-700"></div>
                        </div>
                        <div class="mt-3 sm:pr-8">
                            <h3 class="text-lg font-semibold text-gray-900 pb-4">Kepulangan Umroh (Lokal) </h3>
                            <time class="block mb-2 text-sm font-medium leading-none text-gray-900 ">1 Januari 2024</time>
                            <p class="text-base font-normal text-gray-500 ">A. Jamaah bisa dijemput langsung <br  /> di Bandara Adi Sumarmo</p>

                            {
                                trutie5 ? <>
                                    <p class="text-base font-normal text-gray-500 ">B. jamaah bisa dijemput  di kantor MIW kota Jogja</p>
                                    <p class="text-base font-normal text-gray-500 ">C. Jamaah diantar ke rumah</p>
                                    <p class="text-base font-normal text-gray-500 ">D. Jamaah yang dijemput atau yang diantar ke rumah di kenakan biaya transport</p>
                                </> : null
                            }
                            <button className='text-white bg-gradient-to-br from-amber-600 via-red-600 to-fuchsia-600 px-6 py-2 flex  flex-row mt-5' onClick={() => onChangeEfect6(!trutie5)}>Info Lebih Lanjut
                                <svg
                                    className={`w-6 h-6  transition-transform transform ${trutie5 ? 'rotate-180' : 'rotate-0'
                                        }`}
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M19 9l-7 7-7-7"></path>
                                </svg></button>
                        </div>
                    </li>

                </ol>

            </div>
            <div className='mf:block lg:hidden px-10'>
                <ol class="relative border-l border-blue-950 ">
                    <li class="mb-10 ml-6">
                        <span class="absolute flex items-center justify-center w-6 h-6 bg-orange-600 ring-amber-500 rounded-full -left-3 ring-8  dark:ring-gray-900 dark:bg-orange-500">
                            <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="55.5" cy="55.5" r="55.5" fill="url(#paint0_linear_6_4)" />
                                <path d="M62.1818 32.4545V79H56.5455V38.3636H56.2727L44.9091 45.9091V40.1818L56.5455 32.4545H62.1818Z" fill="white" />
                                <defs>
                                    <linearGradient id="paint0_linear_6_4" x1="56" y1="56" x2="151" y2="-15" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FA6E08" />
                                        <stop offset="1" stop-color="#FA6E08" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </span>
                        <h3 class="flex items-center mb-1 text-md font-bold text-gray-900 ">Chat dan Booking Umroh ke Marketing </h3>
                        <time class="block mb-2 text-sm font-medium leading-none  ">Sebelum 17 November 2023 <br />(Selama Seat Umroh Masih Tersedia)</time>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500 ">A. Konsultasi chat WA ke ustadz H. Surono Achmad, S.Pd</p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700 font-normal lg:text-gray-500 ">B. Bila menghendaki disediakan fasilitas pembiayaaan untuk umroh</p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700 font-semibold lg:text-gray-700 ">Sebaiknya segera hubungi/chat marketing atau booking sekarang, karena keterbatasan seat umroh. </p>
                        <a href="#" class="inline-flex items-center px-4 py-2 text-sm font-medium  border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700 bg-orange-600 text-white dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700">Booking Umroh</a>
                    </li>
                    <li class="mb-10 ml-6">
                        <span class="absolute flex items-center justify-center w-6 h-6 bg-orange-600 ring-amber-500 rounded-full -left-3 ring-8  dark:ring-gray-900 dark:bg-orange-500">
                            <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="55.5" cy="55.5" r="55.5" fill="url(#paint0_linear_6_5)" />
                                <path d="M41.8182 79V74.9091L57.1818 58.0909C58.9848 56.1212 60.4697 54.4091 61.6364 52.9545C62.803 51.4848 63.6667 50.1061 64.2273 48.8182C64.803 47.5152 65.0909 46.1515 65.0909 44.7273C65.0909 43.0909 64.697 41.6742 63.9091 40.4773C63.1364 39.2803 62.0758 38.3561 60.7273 37.7045C59.3788 37.053 57.8636 36.7273 56.1818 36.7273C54.3939 36.7273 52.8333 37.0985 51.5 37.8409C50.1818 38.5682 49.1591 39.5909 48.4318 40.9091C47.7197 42.2273 47.3636 43.7727 47.3636 45.5455H42C42 42.8182 42.6288 40.4242 43.8864 38.3636C45.1439 36.303 46.8561 34.697 49.0227 33.5455C51.2045 32.3939 53.6515 31.8182 56.3636 31.8182C59.0909 31.8182 61.5076 32.3939 63.6136 33.5455C65.7197 34.697 67.3712 36.25 68.5682 38.2045C69.7652 40.1591 70.3636 42.3333 70.3636 44.7273C70.3636 46.4394 70.053 48.1136 69.4318 49.75C68.8258 51.3712 67.7652 53.1818 66.25 55.1818C64.75 57.1667 62.6667 59.5909 60 62.4545L49.5455 73.6364V74H71.1818V79H41.8182Z" fill="white" />
                                <defs>
                                    <linearGradient id="paint0_linear_6_5" x1="56" y1="56" x2="151" y2="-15" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FA6E08" />
                                        <stop offset="1" stop-color="#FA6E08" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>

                        </span>
                        <h3 class="text-md font-bold text-gray-900 ">Mengumpulkan Syarat Pendaftaran ke Marketing</h3>
                        <time class="block mb-2 text-sm font-medium leading-none  ">Sebelum 17 November 2023</time>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500 ">	A. Foto atau fotocopy KTP</p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500 ">	B. Foto atau fotocopy KK</p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500 ">	C. Foto atau fotocopy Buku Nikah bagi suami istri / foto atau fotocopy Akte Lahir foto atau fotocopy Ijazah bagi yang belum menikah</p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500 ">	D. Pas foto ukuran 4 x 6 = 3 lembar latar belakang putih, tidak berkacamata,<br /> dan menggunakan hijab bagi perempuan. </p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500 ">	E. Pas foto ukuran 3 x 4 = 3 lembar latar belakang putih, tampak wajah 80%, pose senyum.</p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500 ">    F. Membayar DP minimal Rp. 5.000.000,-</p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500 ">    NB:Semua syarat diatas dikirimkan ke ustadz H. Surono Achmad, S.Pd melalui WA-</p>

                    </li>
                    <li class="ml-6">
                        <span class="absolute flex items-center justify-center w-6 h-6 bg-orange-600 ring-amber-500 rounded-full -left-3 ring-8  dark:ring-gray-900 dark:bg-orange-500">
                            <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="55.5" cy="55.5" r="55.5" fill="url(#paint0_linear_6_8)" />
                                <path d="M58.5455 79.6364C55.5455 79.6364 52.8712 79.1212 50.5227 78.0909C48.1894 77.0606 46.3333 75.6288 44.9545 73.7955C43.5909 71.947 42.8485 69.803 42.7273 67.3636H48.4545C48.5758 68.8636 49.0909 70.1591 50 71.25C50.9091 72.3258 52.0985 73.1591 53.5682 73.75C55.0379 74.3409 56.6667 74.6364 58.4545 74.6364C60.4545 74.6364 62.2273 74.2879 63.7727 73.5909C65.3182 72.8939 66.5303 71.9242 67.4091 70.6818C68.2879 69.4394 68.7273 68 68.7273 66.3636C68.7273 64.6515 68.303 63.1439 67.4545 61.8409C66.6061 60.5227 65.3636 59.4924 63.7273 58.75C62.0909 58.0076 60.0909 57.6364 57.7273 57.6364H54V52.6364H57.7273C59.5758 52.6364 61.197 52.303 62.5909 51.6364C64 50.9697 65.0985 50.0303 65.8864 48.8182C66.6894 47.6061 67.0909 46.1818 67.0909 44.5455C67.0909 42.9697 66.7424 41.5985 66.0455 40.4318C65.3485 39.2651 64.3636 38.3561 63.0909 37.7045C61.8333 37.053 60.3485 36.7273 58.6364 36.7273C57.0303 36.7273 55.5152 37.0227 54.0909 37.6136C52.6818 38.1894 51.5303 39.0303 50.6364 40.1364C49.7424 41.2273 49.2576 42.5455 49.1818 44.0909H43.7273C43.8182 41.6515 44.553 39.5151 45.9318 37.6818C47.3106 35.8333 49.1136 34.3939 51.3409 33.3636C53.5833 32.3333 56.0455 31.8182 58.7273 31.8182C61.6061 31.8182 64.0758 32.4015 66.1364 33.5682C68.197 34.7197 69.7803 36.2424 70.8864 38.1364C71.9924 40.0303 72.5455 42.0758 72.5455 44.2727C72.5455 46.8939 71.8561 49.1288 70.4773 50.9773C69.1136 52.8258 67.2576 54.1061 64.9091 54.8182V55.1818C67.8485 55.6667 70.1439 56.9167 71.7955 58.9318C73.447 60.9318 74.2727 63.4091 74.2727 66.3636C74.2727 68.8939 73.5833 71.1667 72.2045 73.1818C70.8409 75.1818 68.9773 76.7576 66.6136 77.9091C64.25 79.0606 61.5606 79.6364 58.5455 79.6364Z" fill="white" />
                                <defs>
                                    <linearGradient id="paint0_lislatenear_6_8" x1="56" y1="56" x2="151" y2="-15" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FA6E08" />
                                        <stop offset="1" stop-color="#FA6E08" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>

                        </span>
                        <h3 class="text-md font-bold text-gray-900 ">Ketentuan Pembayaran DP dan Pelunasan</h3>
                        <time class="block mb-2 text-sm font-medium leading-none  ">Sebelum 17 November 2023</time>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500">
                            A. DP bisa ditransfer ke Rekening PT. Madinah Iman Wisata <br />
                        </p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500">
                            B. Mohon semua pembayaran melalui transfer ke rekening MIW di bawah ini atau dibayarkan tunai di kantor MIW setempat dengan meminta tanda terima<br />
                            foto bukti transfer(bukti pembayaran) dan KTP dikirimkan ke WA ustadz H. Surono Achmad, S.Pd
                            <br />
                            C. Pelunasan  bisa ditransfer ke Rekening PT. Madinah Iman Wisata
                            <br /> Paling lambat 1 bulan sebelum keberangkatan
                            <br /><span className='font-extrabold text-lg text-red-500'>Rekening</span>
                            <br /> <b><strong><span className='text-green-600 font-extrabold'>BSI</span> 7770000542</strong></b>
                            <br /> <b><strong><span className='text-green-600 font-extrabold'>BRI</span> 0334.01002958.563</strong></b></p>
                    </li>
                </ol>
                <ol class="relative border-l border-blue-950 ">
                    <li class="mb-10 ml-6">
                        <span class="absolute flex items-center justify-center w-6 h-6 bg-orange-600 ring-amber-500 rounded-full -left-3 ring-8  dark:ring-gray-900 dark:bg-orange-500">
                            <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="55.5" cy="55.5" r="55.5" fill="url(#paint0_linear_6_11)" />
                                <path d="M38.7273 69.4545V64.8182L59.1818 32.4545H62.5455V39.6364H60.2727L44.8182 64.0909V64.4545H72.3636V69.4545H38.7273ZM60.6364 79V68.0455V65.8864V32.4545H66V79H60.6364Z" fill="white" />
                                <defs>
                                    <linearGradient id="paint0_linear_6_11" x1="56" y1="56" x2="151" y2="-15" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FA6E08" />
                                        <stop offset="1" stop-color="#FA6E08" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>


                        </span>
                        <h3 class="text-md font-bold text-gray-900 ">Pembuatan Passport dan  Manasik  </h3>
                        <time class="block mb-2 text-sm font-medium leading-none  ">Sebelum 17 November 2023</time>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500">A. Bila berkenan Jamaah akan dibantu dalam  pembuatan passport baru untuk umroh. <strong>Tanpa dipunggut biaya.</strong></p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500">B. Jamaah dibimbing manasik sebelum dan saat umroh oleh <strong>pembimbing bersertifikat</strong></p>
                    </li>
                    <li class="ml-6">
                        <span class="absolute flex items-center justify-center w-6 h-6 bg-orange-600 ring-amber-500 rounded-full -left-3 ring-8  dark:ring-gray-900 dark:bg-orange-500">
                            <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="55.5" cy="55.5" r="55.5" fill="url(#paint0_linear_6_14)" />
                                <path d="M56.3636 79.6364C53.697 79.6364 51.2955 79.1061 49.1591 78.0455C47.0227 76.9848 45.3106 75.5303 44.0227 73.6818C42.7348 71.8333 42.0303 69.7273 41.9091 67.3636H47.3636C47.5758 69.4697 48.5303 71.2121 50.2273 72.5909C51.9394 73.9545 53.9848 74.6364 56.3636 74.6364C58.2727 74.6364 59.9697 74.1894 61.4545 73.2955C62.9545 72.4015 64.1288 71.1742 64.9773 69.6136C65.8409 68.0379 66.2727 66.2576 66.2727 64.2727C66.2727 62.2424 65.8258 60.4318 64.9318 58.8409C64.053 57.2348 62.8409 55.9697 61.2955 55.0455C59.75 54.1212 57.9848 53.6515 56 53.6364C54.5758 53.6212 53.1136 53.8409 51.6136 54.2955C50.1136 54.7348 48.8788 55.303 47.9091 56L42.6364 55.3636L45.4545 32.4545H69.6364V37.4545H50.1818L48.5455 51.1818H48.8182C49.7727 50.4242 50.9697 49.7955 52.4091 49.2955C53.8485 48.7955 55.3485 48.5455 56.9091 48.5455C59.7576 48.5455 62.2955 49.2273 64.5227 50.5909C66.7652 51.9394 68.5227 53.7879 69.7955 56.1364C71.0833 58.4848 71.7273 61.1667 71.7273 64.1818C71.7273 67.1515 71.0606 69.803 69.7273 72.1364C68.4091 74.4545 66.5909 76.2879 64.2727 77.6364C61.9545 78.9697 59.3182 79.6364 56.3636 79.6364Z" fill="white" />
                                <defs>
                                    <linearGradient id="paint0_linear_6_14" x1="56" y1="56" x2="151" y2="-15" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FA6E08" />
                                        <stop offset="1" stop-color="#FA6E08" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>

                        </span>
                        <h3 class="text-md font-bold text-gray-900 ">Keberangkatan Umroh  </h3>
                        <time class="block mb-2 text-sm font-medium leading-none text-gray-900 ">17 Desember 2023</time>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500">A. Keberangkatan dari Bandara Adi Soemarmo Solo, jamaah langsung menuju bandara pada jam yang sudah ditentukan </p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500">B. Jika jamaah menghendaki berangkat dari kantor MIW setempat maka biaya transport dari kantor MIW ke Bandara Adi Soemarmo Solo ditanggung oleh calon jamaah</p>
                    </li>
                    <li class="ml-6">
                        <span class="absolute flex items-center justify-center w-6 h-6 bg-orange-600 ring-amber-500 rounded-full -left-3 ring-8  dark:ring-gray-900 dark:bg-orange-500">
                            <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="55.5" cy="55.5" r="55.5" fill="url(#paint0_linear_12_2)" />
                                <path d="M57.2727 79.6364C55.3636 79.6061 53.4545 79.2424 51.5455 78.5455C49.6364 77.8485 47.8939 76.6742 46.3182 75.0227C44.7424 73.3561 43.4773 71.1061 42.5227 68.2727C41.5682 65.4242 41.0909 61.8485 41.0909 57.5455C41.0909 53.4242 41.4773 49.7727 42.25 46.5909C43.0227 43.3939 44.1439 40.7045 45.6136 38.5227C47.0833 36.3258 48.8561 34.6591 50.9318 33.5227C53.0227 32.3864 55.3788 31.8182 58 31.8182C60.6061 31.8182 62.9242 32.3409 64.9545 33.3864C67 34.4167 68.6667 35.8561 69.9545 37.7045C71.2424 39.553 72.0758 41.6818 72.4545 44.0909H66.9091C66.3939 42 65.3939 40.2652 63.9091 38.8864C62.4242 37.5076 60.4545 36.8182 58 36.8182C54.3939 36.8182 51.553 38.3864 49.4773 41.5227C47.4167 44.6591 46.3788 49.0606 46.3636 54.7273H46.7273C47.5758 53.4394 48.5833 52.3409 49.75 51.4318C50.9318 50.5076 52.2348 49.7955 53.6591 49.2955C55.0833 48.7955 56.5909 48.5455 58.1818 48.5455C60.8485 48.5455 63.2879 49.2121 65.5 50.5455C67.7121 51.8636 69.4848 53.6894 70.8182 56.0227C72.1515 58.3409 72.8182 61 72.8182 64C72.8182 66.8788 72.1742 69.5152 70.8864 71.9091C69.5985 74.2879 67.7879 76.1818 65.4545 77.5909C63.1364 78.9848 60.4091 79.6667 57.2727 79.6364ZM57.2727 74.6364C59.1818 74.6364 60.8939 74.1591 62.4091 73.2045C63.9394 72.25 65.1439 70.9697 66.0227 69.3636C66.9167 67.7576 67.3636 65.9697 67.3636 64C67.3636 62.0758 66.9318 60.3258 66.0682 58.75C65.2197 57.1591 64.0455 55.8939 62.5455 54.9545C61.0606 54.0152 59.3636 53.5455 57.4545 53.5455C56.0152 53.5455 54.6742 53.8333 53.4318 54.4091C52.1894 54.9697 51.0985 55.7424 50.1591 56.7273C49.2348 57.7121 48.5076 58.8409 47.9773 60.1136C47.447 61.3712 47.1818 62.697 47.1818 64.0909C47.1818 65.9394 47.6136 67.6667 48.4773 69.2727C49.3561 70.8788 50.553 72.1742 52.0682 73.1591C53.5985 74.1439 55.3333 74.6364 57.2727 74.6364Z" fill="white" />
                                <defs>
                                    <linearGradient id="paint0_linear_12_2" x1="56" y1="56" x2="151" y2="-15" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FA6E08" />
                                        <stop offset="1" stop-color="#FA6E08" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>


                        </span>
                        <h3 class="text-md font-bold text-gray-900 ">Kepulangan Umroh (Lokal)  </h3>
                        <time class="block mb-2 text-sm font-medium leading-none text-gray-900 ">1 Januari 2024</time>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500">A. Jamaah bisa dijemput langsung di Bandara Adi Sumarmo</p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500">B. Jamaah bisa dijemput di kantor MIW Kota Jogja</p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500">C. Jamaah diantar ke rumah</p>
                        <p class="lg:text-base mf:text-sm mf:text-slate-700  font-normal lg:text-gray-500">D. Jamaah yang dijemput atau yang diantar ke rumah di kenakan biaya transport</p>
                    </li>
                </ol>
            </div>
        </div>
    )
}
