import React from 'react'
import RTW from "../assets/rtw01.jpg"
export const Brocure = () => {
    return (
        <div className='flex lg:flex-row mf:flex-col lg:w-full mx-auto gap-4 items-center justify-center py-10 '>
            <img src={RTW} className='w-[400px] h-[60%]' alt="" />
            <img src={RTW} className='w-[400px] h-[60%] mf:hidden lg:block' alt="" />
            <img src={RTW} className='w-[400px] h-[60%] mf:hidden lg:hidden 2xl:block' alt="" />
        </div>
    )
}
