import React from 'react'
import HC300 from "../assets/HC-300.svg"
export const Perlengkapan = () => {
    return (
        <div className='flex mf:flex-col-reverse lg:flex-row  items-center justify-center bg-amber-200 lg:w-full lg:mx-auto   pb-5 mf:px-10 '>
            <div className='lg:w-7/12 2xl:w-3/6 lg:px-10 2xl:pr-10 flex flex-col gap-4'>
                <h1 className='text-3xl font-extrabold leading-8'>Perlengkapan <span className='text-gray-600 text-2xl'>Umroh</span></h1>
                <p className='text-lg font-medium tracking-wide lg:w-3/4 leading-7'>Buku do'a, koper hardcase bagasi, koper hardcase kabin,
                    kain ikhrom (Pria), bergo (Wanita), kerudung (Wanita), slayer, syal, tas paspor, kain batik, sertifikat umroh, asuransi all risk (kehilangan barang, sakit, operasi atau wafat)</p>
                <a href="https://api.whatsapp.com/send?phone=6281904235462&text=Assalamuallaikum%20Ustadz%20Haji%20Surono%20%2CUmroh%20akhir%20tahun%202023%20Saya%20Insya%20Allah%20Mau%20Booking%20,%20masih%20ada%20tidak%20ya%20saya%20berminat" class="inline-flex justify-center items-center py-4  text-base font-medium text-center text-white rounded-lg bg-gradient-to-tl from-fuchsia-500 via-red-500 to-orange-600 hover:bg-green-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-teal-900 lg:w-2/6 mf:w-4/6 mf:mx-auto lg:mx-0 mt-5">
                    Booking Umroh
                    <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </a>
            </div>
            <div>
                <img src={HC300} className='h-96 w-96' alt="" />
            </div>
        </div>
    )
}
