import logo from './logo.svg';
import { HF } from './components/hf';
import { Timeline } from './components/timeline';
import { MIWProud } from './components/MIWProud';
import { FAQ } from './container/FAQ';
import { Brocure } from './components/Brocure';
import { Perlengkapan } from './components/Perlengkapan';
import { Pricing } from './components/Pricing';
import { Footer } from './components/footer';
function App() {
  return (
    <div className='bg-gradient-to-b from-amber-300 to-white '>
      <HF />
      <Brocure/>
      <Pricing/>
      <MIWProud/>
      <Timeline/>
      <Perlengkapan/>
      <FAQ/>
      <Footer/>
    </div>
  );
}

export default App;
