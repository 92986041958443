import React from 'react'
import surono from "../assets/surono.png"
import {LobsterHeading} from "../components/HtmlDOM/LobsterHeading"
export const HF = () => {
    return (
        <div className='bg-gradient-to-bl from-white to-amber-300 2xl:hf__header-background ' >
            <div className='px-30 '>
                <section className='flex lg:flex-row mf:flex-col-reverse items-center justify-center relative'>
                    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 grid lg:grid-cols-2 gap-8 lg:gap-16">
                        <div class="flex flex-col justify-center">
                            <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl ">Umroh Akhir Tahun Bersama <span className='flex-end  bg-gradient-to-r from-orange-500 via-rose-500 to-red-600 bg-clip-text text-transparent'>Madinah Iman Wisata</span> 2023</h1>
                            <p class="mb-8 text-lg font-normal text-gray-600 lg:text-xl ">Saatnya mewujudkan impian suci kita untuk umroh bersama keluarga tercinta atau umroh bersama teman-teman kantor tercinta. Konsultasikan rencana ibadah umroh dan haji kita bersama Madinah Iman Wisata(MIW) sekarang. Bersiaplah untuk memulai perjalanan menuju reuni abadi di surga Insya Allah.</p>
                            <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                                <a href="https://api.whatsapp.com/send?phone=6281904235462&text=Assalamuallaikum%20Umroh%20Haji%20Sekeluarga%20%2Cmau%20tanya%20kuota%20umroh%20akhir%20tahun%202023%20masih%20ada%20tidak%20ya%20saya%20berminat" class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-gradient-to-tl from-fuchsia-500 via-red-500 to-orange-600 hover:bg-green-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-teal-900">
                                    Konsultasi via WA
                                    <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                    </svg>
                                </a>

                            </div>
                        </div>
                    </div>
                    <div>
                        <LobsterHeading text="H. Surono Achmad, S.Pd" size="text-[35px]" costum_css="mf:block lg:hidden" />
                    </div>
                    <div className='lg:absolute lg:pl-96 lg:top-[-70px]'>
                        <img src={surono} className='lg:h-[560px] mf:h-[300px]' alt="ustadz achmad surono miw agen" />
                        <LobsterHeading text="H. Surono Achmad, S.Pd" size="text-[50px]" costum_css="mf:hidden lg:block"/>
                    </div>
                </section>
            </div>
        </div>
    )
}
